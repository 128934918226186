import React from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import { LinkedLogo, NamedLink } from '../../../../components';

import SectionContainer from '../SectionContainer';
import css from './SectionFooter.module.css';

// The number of columns (numberOfColumns) affects styling

// Section component that's able to show blocks in multiple different columns (defined by "numberOfColumns" prop)
const SectionFooter = props => {
  const {
    sectionId,
    className,
    appearance,
    options,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  // use block builder instead of mapping blocks manually

  const barStyles = `relative before:content-[""] before:h-[20px] before:md:block before:w-[1px] before:absolute before:hidden before:-left-6 before:top-2 before:bg-bazaa`;
  const barStylesFirst = `relative before:content-[""] before:h-[20px] before:md:block before:w-[1px] before:absolute before:hidden before:-left-6 before:top-2`;

  return (
    <SectionContainer
      as="footer"
      id={sectionId}
      className={className || css.root}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className="px-6 md:px-8 pb-10 bg-footer flex flex-col">
        <div className="max-w-[1360px] w-full mx-auto">
          <div className="flex flex-col md:grid gap-8 md:gap-0 md:grid-cols-[48%_15%_13%_13%_14%] pt-16">
            <div className="md:pr-[10%] xl:pr-[20%] mb-8">
              <div>
                <LinkedLogo rootClassName={css.logoLink} logoClassName={css.logoImage} />
              </div>
              <p>Be the first to know about new arrivals, promotions and styling tips</p>
              <div className={css.footerSignup}>
                <input placeholder="Enter your email" className="text-base" />
                <NamedLink name="SignupPage">
                  <button className="font-bold">SIGN UP</button>
                </NamedLink>
              </div>
            </div>
            <div className="md:px-6">
              <ul className="my-0">
                <li className="font-bold pb-2">SHOP BY CATEGORY</li>
                <li>
                  <a href="https://bazaa.com.au/collections/furniture">Furniture</a>
                </li>
                <li>
                  <a href="https://bazaa.com.au/collections/home-decor">Home Decor</a>
                </li>
                <li>
                  <a href="https://bazaa.com.au/collections/lighting">Lighting</a>
                </li>
                <li>
                  <a href="https://bazaa.com.au/collections/art">Art</a>
                </li>
                <li>
                  <a href="https://bazaa.com.au/collections/rugs">Rugs</a>
                </li>
              </ul>
            </div>
            <div className="md:px-6">
              <ul className="my-0">
                <li className="font-bold pb-2">SELLER FAQS</li>
                <li>
                  <a href="#">Sell With Us</a>
                </li>
                <li>
                  <a href="#">Item Curation</a>
                </li>
                <li>
                  <a href="#">Seller FAQs</a>
                </li>
              </ul>
            </div>
            <div className="md:px-6">
              <ul className="my-0">
                <li className="font-bold pb-2">HELP CENTRE</li>
                <li>
                  <a href="#">Help Centre</a>
                </li>
                <li>
                  <a href="#">Contact Us</a>
                </li>
                <li>
                  <a href="#">Delivery Policy</a>
                </li>
                <li>
                  <a href="#">Returns Policy</a>
                </li>
                <li>
                  <a href="#">Buyer FAQs</a>
                </li>
              </ul>
            </div>
            <div className="md:px-6">
              <ul className="my-0">
                <li className="font-bold pb-2">OUR COMPANY</li>
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Bazaa Guarantee</a>
                </li>
                <li>
                  <a href="#">Press</a>
                </li>
                <li>
                  <a href="#">Careers</a>
                </li>                
              </ul>
            </div>
            {/* <div className="flex flex-col md:flex-row text-bazaa font-bold mt-8 md:mt-0 md:gap-14">
              </div> */}
          </div>
          <ul className="flex flex-col md:flex-row md:gap-12 text-sm mt-10">
            <li className={barStylesFirst}>Bazaa Pty Ltd</li>
            <li className={barStyles}>
              <NamedLink name="TermsOfServicePage">Terms & Conditions</NamedLink>
            </li>
            <li className={barStyles}>
              <a href="#">Accesssibility</a>
            </li>
            <li className={barStyles}>
              <NamedLink name="PrivacyPolicyPage">Privacy Policy</NamedLink>
            </li>
          </ul>
        </div>
      </div>
    </SectionContainer >
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  numberOfColumns: 1,
  socialMediaLinks: [],
  slogan: null,
  copyright: null,
  appearance: null,
  blocks: [],
  options: null,
};

SectionFooter.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  numberOfColumns: number,
  socialMediaLinks: arrayOf(object),
  slogan: object,
  copyright: object,
  appearance: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default SectionFooter;